import React, { useState, useEffect } from "react"
import Skeleton from 'react-loading-skeleton';

const Nickname = () => {

// Client-side Runtime Data Fetching
const [nickName, setNickName] = useState('')
useEffect(() => {
  // get data from GitHub api
  fetch(`/.netlify/functions/random`)
    .then(response => response.json())
    .then(data => {
      //console.log(data)
      setTimeout(setNickName(data.title), 1000)
    }) // set data for the number of stars
}, [])
  return (
    <>
      {nickName || <Skeleton width={300} />}
    </>
  )
}

export default Nickname
