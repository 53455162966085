import PropTypes from "prop-types"
import React from "react"
import {
  Container
} from 'reactstrap';

import Nickname from './nickname.js';

const Header = ({ siteTitle }) => {
  return (
    <header>
      <Container>
        <h1>
          <div>{siteTitle}</div>
          <div><span id="nickname"><Nickname /></span></div>
        </h1>
      </Container>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
